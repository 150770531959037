@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;
@use "../../shared/signupSteps.module.scss" as *;

.priceSummary {
	font-weight: $normal-base;
	font-size: 1.6rem;
	line-height: 2rem;
	color: $deep-green;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 15px;

	& > * {
		flex-basis: 50%;
		flex-grow: 1;
		flex-shrink: 1;

		&:nth-child(even) {
			text-align: right;
		}
	}

	& > div:nth-child(2n) {
		color: $purple;
		font-size: 1.8rem;
		font-weight: $bold-base;
	}

	.discount {
		color: $orange;
	}

	.discountPrice {
		color: $orange !important;
	}

	.total {
		font-weight: $bold-base;
		font-size: 1.8rem;
		line-height: 2rem;
	}
}

@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;
@use "../Input/input.module.scss" as *;

.listbox {
	display: none;
	position: absolute;
	background-color: $color-lightest;
	z-index: 3;
	padding: 7px 0;
	min-width: 220px;
	max-height: 300px;
	font-weight: $bold-base;
	width: 100%;
	overflow: auto;
	margin-top: -1px;
	text-align: left;
	border: 1px solid $light-grey;
	color: $deep-green;

	&.open {
		display: block;
	}

	&.above {
		bottom: 100%;
	}

	.listbox__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		height: 25px;
		background-color: $color-lightest;
		border-radius: 23px;
		background-image: url(/images/icon-up.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 15px 12px;
		text-indent: -9999px;
		overflow: hidden;
		margin: 0;
	}
}

.inputCont {
	position: relative;
}

.downIcon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.selectWrapper {
	position: relative;

	&.isInline {
		label {
			margin-bottom: 0;
			padding-bottom: 10px;
		}
	}

	&.tiny {
		display: inline-block;
		margin-left: 5px;
		text-align: left;

		.listbox {
			width: 75px;
			min-width: 0;
			padding: 2px 0;
		}

		input {
			height: 26px;
			line-height: 26px;
			padding: 5px;
			width: 75px;
			text-align: left;
			font-size: 1.4rem;
		}

		span {
			right: 5px;
		}
	}
}

.extraText {
	display: inline-block;
	margin-left: 5px;
	font-weight: bold;
	font-size: 1.8rem;
}

.inputSelect {
	padding-right: 40px;
}

.floatingError {
	position: absolute;
	background-color: $red;
	margin-top: 10px;
	z-index: 2;
	font-size: 1.6rem;
	font-weight: normal;
	padding: 2px 6px;

	p {
		font-size: 1.4rem;
		line-height: 2.2rem;
		padding: 0;
		margin: 0;
		font-weight: normal;
	}
}

@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.option {
	cursor: pointer;
	margin-top: 0;
	margin-bottom: 0;
	color: $color-darkest;
	padding: 8px calc($gutter / 2);
	font-weight: $bold-base;
	font-size: 1.6rem;
	line-height: 2rem;
	display: flex;

	&:first-child {
		margin-top: 3px;
	}
	&:last-child {
		margin-bottom: 3px;
	}
	&.active,
	&:hover {
		color: $purple;
		background: rgba($light-grey, 0.2);
	}
	&.selected {
		color: $yellow;
	}

	&.tiny {
		padding: 4px calc($gutter / 2);
		font-size: 1.4rem;
	}

	.caption {
		margin-left: auto;
		color: $light-green;
		font-size: 1.2rem;
		font-weight: lighter;
		text-transform: uppercase;
	}
}

@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;
@use "sass:color";

.body {
	width: 100%;
	height: 100%;
	background: $off-white;
}

.wrapper {
	width: 100%;
	min-height: 100%;
	position: relative;
	overflow: hidden;
}

.mainContent {
	padding-bottom: 90px;

	&.hasLargerFooter {
		padding-bottom: 180px;
	}

	&.hasFooter {
		padding-bottom: 0px;
	}
}

.siteNotice {
	text-align: center;
	background-color: color.scale($color-primary, $lightness: 15%);
	padding-left: 20px;
	padding-right: 20px;

	p {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
		padding: 15px 0;
		@include font-size(13);
		font-weight: $bold-base;
		line-height: 1.3;
		color: $color-dark;
		padding-left: 35px;
		width: auto;
		display: inline-block;
		background-image: url(/images/icon-delivery.svg);
		background-size: 25px 25px;
		background-position: left center;
		background-repeat: no-repeat;
		text-align: left;

		@include respond-min($xs) {
			text-align: center;
			background-position: right center;
			padding-right: 25px;
			background-size: 16px 16px;
		}

		span {
			display: block;

			@include tablet() {
				display: inline;
			}
		}
	}
}

@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.basketIcon {
	position: relative;
	grid-column: 6 / span 1;
	color: $white;
	top: -3px;

	@include tablet() {
		grid-column: 12 / span 1;
	}
}

.basketBackground {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 100%;
	opacity: 0;
	transition:
		opacity 0.4s,
		left 0s 0.4s;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.5);

	&.bgOpen {
		left: 0;
		opacity: 1;
		transition:
			opacity 0.4s,
			left 0s 0s;
	}
}

.basket {
	background-color: $deep-green;
	position: fixed;
	height: 100%;
	width: 280px;
	right: -280px;
	top: 0;
	z-index: 101;
	transition: right 0.4s;
	padding: 20px;

	@include tablet() {
		width: 350px;
		right: -350px;
	}

	&.open {
		right: 0;
	}

	h3,
	h4 {
		text-align: center;
		color: $white;
	}

	div {
		color: $white;
	}

	ul li {
		color: $white;
	}
	.checkoutBtn {
		margin-top: 20px;
	}
}

@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.error {
	color: $red;
	margin-top: calc($gutter / 2);
	font-weight: bold;
	margin-bottom: 0;
	font-weight: $normal-base;
	font-size: 1.4rem;
	line-height: 1.6rem;
}
